import { PM_TYPE, RESULT_STATUS } from '@/constants'
import {
  TEST_CASES_ROUTE,
  TEST_CASE_VALIDATION_RESULTS_ROUTE,
  BOM_VALIDATION_RESULTS_ROUTE,
  BOM_VALIDATIONS_ROUTE,
  UNUSABLE_CHOICES_VALIDATION_RESULTS_ROUTE,
  TEST_CASE_VALIDATIONS_ROUTE,
  TEST_CASE_VALIDATION_COMPARISON_ROUTE,
  NOT_USUABLE_CHOICES_COMPARISON_ROUTE
} from '@/router/routeNames'

const testCaseRoutesName = [TEST_CASES_ROUTE.name, BOM_VALIDATIONS_ROUTE.name, TEST_CASE_VALIDATIONS_ROUTE.name]

const unusableChoicesRoutesName = [
  UNUSABLE_CHOICES_VALIDATION_RESULTS_ROUTE.name,
  NOT_USUABLE_CHOICES_COMPARISON_ROUTE.name
]

const testCaseResultsRoutesName = [
  TEST_CASE_VALIDATION_RESULTS_ROUTE.name,
  TEST_CASE_VALIDATION_COMPARISON_ROUTE.name
]

export const VALIDATION_ROUTES_AVAILABLE = {
  isTestCasePage: TEST_CASES_ROUTE.name,
  isTestCaseValidationResultsPage: TEST_CASE_VALIDATION_RESULTS_ROUTE.name,
  isTestCaseComparisonPage: TEST_CASE_VALIDATION_COMPARISON_ROUTE.name,
  isBomValidationResultsPage: BOM_VALIDATION_RESULTS_ROUTE.name,
  isBomValidationsRoute: BOM_VALIDATIONS_ROUTE.name,
  isUnusableChoicesResultsPage: UNUSABLE_CHOICES_VALIDATION_RESULTS_ROUTE.name
}

export function routeNameCheck(routeName) {
  return {
    isTestCasesPage: testCaseRoutesName.includes(routeName) && routeName !== BOM_VALIDATIONS_ROUTE.name,
    isBomValidationsPage: routeName === BOM_VALIDATIONS_ROUTE.name,
    isUnusableChoicesPage: unusableChoicesRoutesName.includes(routeName),
    isValidationsHeader: testCaseRoutesName.includes(routeName),
    isTestCasesResultsPage: testCaseResultsRoutesName.includes(routeName)
  }
}

export const formatProductModel = ({ productModelType, businessName, userExportsName }) => {
  switch (productModelType) {
    case PM_TYPE.USER_EXPORTS:
      return (
          <div class="table-cell-pm">
              <span class="pills__ue">
                <mi-tooltip>User Export</mi-tooltip>
              </span>
              <span class="table-cell__text">{userExportsName}</span>
          </div>
      )
    case PM_TYPE.PRODUCT_MODEL:
      return (
          <div class="table-cell-pm">
              <span class="pills__pm">
                <mi-tooltip>Product Model</mi-tooltip>
              </span>
              <span class="table-cell__text">{businessName}</span>
          </div>
      )
    default:
      return '-'
  }
}

export const validationsComparisonResultsColumns = [
  {
    name: 'id',
    label: 'Row',
    align: 'left',
    field: 'id',
    headerStyle: 'width: 20px',
    sortable: true
  },
  {
    name: 'logicalExpression',
    label: 'Logical expression',
    align: 'left',
    field: 'logicalExpression',
    sortable: true
  },
  {
    name: 'pp',
    align: 'left',
    label: 'Planning period',
    headerStyle: 'width: 300px',
    sortable: true,
    field: 'planningPeriod'
  },
  {
    name: 'status',
    label: 'Status',
    align: 'left',
    field: 'mainTestCaseValidationStatus',
    headerStyle: 'width: 300px',
    sortable: true,
    format: value => RESULT_STATUS[value] || {}
  }
]
