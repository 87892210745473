<template>
  <div class="new-validation-dialog-header">
    <div>
      <h4 class="new-validation-dialog-header__title"> New validation </h4>
      <p class="new-validation-dialog-header__subtitle">
        Create a new {{ newValidationType }} validation
      </p>
    </div>
    <mi-btn
      v-close-popup
      icon="close"
      icon-type
      icon-size="18px"
      onclose=""
      fab
      flat
    ></mi-btn>
  </div>
</template>

<script>

  export default {
    name: 'NewValidationDialogHeader',
    props: {
      newValidationType: {
        type: String,
        required: true
      }
    },
    emits: ['close:dialog'],
    methods: {
      closeDialog() {
        this.$emit('close:dialog', false)
      }
    }
  }
</script>

<style lang="scss">
  .new-validation-dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 924px;
    max-width: 924px;
    padding: 24px;
    height: 111px;
    margin: 0;
    background-color: $mi-anthracite-50;

    &__title {
      font-size: 1.75rem;
      margin-top: .5rem;
      margin-bottom: .5rem;
      padding-left: 0;
      padding-right: 0;
    }

    &__subtitle {
      font-size: .875rem;
      color: $mi-anthracite-800;
    }
  }

  @media (max-width: $mi-responsive-large-width) {
    .new-validation-dialog-header {
      width: 683px;
      max-width: 683px;
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .new-validation-dialog-header {
      width: 477px;
      max-width: 477px;
    }
  }
</style>
