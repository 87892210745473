<template>
  <div v-if="testCaseId" class="col col-validations">
    <mi-table
      :columns="columns"
      :loading="areTestCaseValidationsLoading"
      :rows="testCaseValidations"
      :table-colspan="columns.length"
      class="test-case-validations-table"
      column-sort-order="da"
      loading-label="Loading validations. Please wait ..."
      no-data-label="No test case validations found"
      row-key="id"
      title="Validations"
      virtual-scroll-sticky-size-start="40"
      hide-pagination
      sticky-header
      striped
      virtual-scroll
      wrap-cells
    >
      <!-- Status column -->
      <!-- Header refresh button -->
      <template #top-left>
        <test-cases-validations-table-header
          :are-test-case-validations-loading="areTestCaseValidationsLoading"
          :fetch-test-case-validations="fetchTestCaseValidations"
        ></test-cases-validations-table-header>
      </template>

      <template #top-right>
        <mi-btn
          color="accent"
          icon="plus"
          icon-size="14px"
          @click="handleNewValidation"
        >
          New Validation
        </mi-btn>

        <test-cases-create-validation
          :model-value="isOpenCreateValidation"
          @update:model-value="handleNewValidation"
        >
        </test-cases-create-validation>
      </template>

      <template #body-cell-status="{ value, row }">
        <!-- Validation status -->
        <mi-td class="test-case-validations-table__status-cell">
          <div class="flex items-center">
            <mi-badge class="text-uppercase q-mr-xs" :color="value.color">
              {{ value.title }}
            </mi-badge>

            <div class="q-ml-auto">
              <!-- Open validation button -->
              <mi-tr-action-btn
                v-show="value.title === RESULT_STATUS.SUCCESS.title"
                icon="exit"
                @click="openTestCaseValidation(row.id)"
              >
                <mi-tooltip> Open </mi-tooltip>
              </mi-tr-action-btn>

              <!-- Delete validation button -->
              <mi-tr-action-btn icon="trash" @click="openDeleteTestCaseValidationDialog(row.id, row.productModelType)">
                <mi-tooltip> Delete </mi-tooltip>
              </mi-tr-action-btn>
            </div>
          </div>
        </mi-td>
      </template>
    </mi-table>

    <!-- Delete validation dialog -->
    <validation-delete-dialog
      v-model="isDeleteValidationDialogShown"
      :delete-fn="deleteTestCaseValidation"
      :validation-id="testCaseValidationIdToDelete"
      :is-user-export-delete-from-daily="isUserExport"
      @delete:validation="handleTestCaseValidationDeletedEvent"
    ></validation-delete-dialog>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  import { deleteTestCaseValidation } from '@/api'
  import formatDate from '@/utils/formatDate'
  import { removePrefixFromPlanningPeriod } from '@/utils/planningPeriod'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'

  import { RESULT_STATUS, PM_TYPE } from '@/constants'
  import { TEST_CASE_VALIDATION_RESULTS_ROUTE } from '@/router/routeNames'

  import ValidationDeleteDialog from '@/components/validations/ValidationDeleteDialog.vue'
  import { TEST_CASES_DELETE_VALIDATION } from '@/utils/analytics/constants'
  import MiTable from '@/packages/@mi-library/MiTable/MiTable.vue'
  import TestCasesCreateValidation
    from '@/components/validations/test-cases/new-validations/TestCasesCreateValidation.vue'
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import { formatProductModel } from '@/components/validations/utils'
  import TestCasesValidationsTableHeader from './test-case-validations-table-header/TestCasesValidationsTableHeader.vue'

  const { mapActions, mapState } = createNamespacedHelpers('validations')

  export default {
    name: 'TestCasesValidationsTable',
    components: { TestCasesValidationsTableHeader, MiBtn, TestCasesCreateValidation, MiTable, ValidationDeleteDialog },
    props: {
      testCaseId: {
        type: String,
        required: false,
        default: ''
      }
    },
    data: () => ({
      RESULT_STATUS,
      deleteTestCaseValidation,
      areTestCaseValidationsLoading: false,
      isDeleteValidationDialogShown: false,
      isUserExport: false,
      testCaseValidationIdToDelete: '',
      isOpenCreateValidation: false,
      columns: [
        {
          name: 'planningPeriodRange',
          align: 'left',
          label: 'Planning Period (Start - Finish)',
          field: ({ planningPeriodRange: { from, to } = {} } = {}) => `
            ${ removePrefixFromPlanningPeriod(from) } - ${ removePrefixFromPlanningPeriod(to) }
          `,
          sortable: true
        },
        {
          name: 'businessName',
          align: 'left',
          label: 'Product Model',
          field: (
            { productModelType, businessName, userExportsName } = {}
          ) => (
            { productModelType, businessName, userExportsName }
          ),
          format: formatProductModel,
          sortable: true
        },
        {
          name: 'validationDate',
          label: 'Validation date',
          align: 'left',
          field: 'validationDate',
          format: value => (value ? formatDate(value) : ''),
          sortable: true
        },
        {
          name: 'status',
          label: 'Status',
          align: 'left',
          field: 'status',
          headerStyle: 'padding-left: .5rem; padding-right: .5rem',
          format: value => RESULT_STATUS[value] || {},
          sortable: true
        }
      ]
    }),
    computed: {
      ...mapState(['testCaseValidations'])
    },
    created() {
      this.$watch(
        () => this.testCaseId, current => {
          current && this.fetchTestCaseValidations()
        },
        { immediate: true }
      )
    },
    methods: {
      ...mapActions(['getTestCaseValidationsList']),

      async fetchTestCaseValidations() {
        this.areTestCaseValidationsLoading = true

        try {
          await this.getTestCaseValidationsList(this.testCaseId)
        }
        finally {
          this.areTestCaseValidationsLoading = false
        }
      },
      handleTestCaseValidationDeletedEvent() {
        this.testCaseValidationIdToDelete = ''
        this.fetchTestCaseValidations()

        // Analytics
        recordAnalytics(TEST_CASES_DELETE_VALIDATION)
      },
      openDeleteTestCaseValidationDialog(testCaseValidationId = '', productModelType = '') {
        this.testCaseValidationIdToDelete = testCaseValidationId
        this.isUserExport = productModelType === PM_TYPE.USER_EXPORTS
        this.isDeleteValidationDialogShown = true
      },
      openTestCaseValidation(testCaseValidationId = '') {
        this.$router.push({
          name: TEST_CASE_VALIDATION_RESULTS_ROUTE.name,
          params: { testCaseValidationId }
        })
      },
      handleNewValidation() {
        if (this.isOpenCreateValidation) this.fetchTestCaseValidations()
        this.isOpenCreateValidation = !this.isOpenCreateValidation
      }
    }
  }
</script>

<style lang="scss" scoped>
  .col-validations {
    margin: 0 8px;
    border-top: .66px solid $mi-anthracite-200;
  }

  .test-case-validations-table {
    min-height: 200px;
    max-height: 300px;
  }

  .test-case-validations-table ::v-deep(.q-table__container) {
    table-layout: fixed;
  }

  .test-case-validations-table .test-case-validations-table__status-cell {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .table-cell-pm {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .pills {
    &__pm,
    &__ue {
      min-width: 16px;
      width: 16px;
      height: 8px;
      border-radius: 4px;
    }

    &__pm {
      background: #cbd3dc;
    }

    &__ue {
      background: #76b3de;
    }
  }
</style>
