import { minLength } from '@vuelidate/validators'
import { createRule } from './common'
import { MIN_LENGTH_ERROR } from './errorMessages'

export default length => {
  const errorMessage = MIN_LENGTH_ERROR.replace('{length}', length || '')
  const { $validator: isValid } = minLength(length)

  return createRule({ isValid, errorMessage })
}
