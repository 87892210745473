<template>
  <div class="new-test-case-val__wrapper">
    <div class="new-test-case-val__header">
      <div class="new-test-case-val__header--toggle">
        <mi-btn
          class="new-test-case-val__header--toggle__btn"
          flat
          :class="isProductModel ? 'new-test-case-val__header--toggle__btn-selected' : ''"
          @click="handleValidationType(validationType.PRODUCT_MODEL)"
        >
          Product Model
        </mi-btn>
        <mi-btn
          class="new-test-case-val__header--toggle__btn"
          flat
          :class="!isProductModel ? 'new-test-case-val__header--toggle__btn-selected' : ''"
          @click="handleValidationType(validationType.USER_EXPORT)"
        >
          Export
        </mi-btn>
      </div>
    </div>
    <mi-form class="new-test-case-form" @submit="handleSubmit">
      <div class="new-test-case-val__form">
        <mi-select
          v-model="validationForm.productModel"
          :display-value="validationForm.productModel.businessName
            || validationForm.productModel.name || selectedType.description"
          class="q-mb-sm mi-select-margin"
          :rules="inputValidationRules"
          :label="selectedType.label"
          option-label="businessName"
          :options="isProductModel ? productModels : userExports.resultsType"
          options-selected-class="text-accent"
          outlined
        >
          <template #no-option>
            <mi-list-item>
              <mi-list-item-section class="text-grey">
                No results
              </mi-list-item-section>
            </mi-list-item>
          </template>
        </mi-select>
        <mi-text-field
          v-model="validationForm.planningPeriod"
          :rules="[customPlanningPeriodValidationRules]"
          :class="isPlanningPeriodEmpty && 'new-bom-validation__form--mask'"
          label="Planning period"
          mask="###### - ######"
          fill-mask="_"
          outlined
          prefix="PP"
        >
        </mi-text-field>
      </div>
      <div class="new-test-case-val__actions">
        <mi-btn flat @click="closeDialog"> Cancel </mi-btn>
        <mi-btn type="submit" :loading="OnDemandExportsTestCasesValidation">
          Create validation
        </mi-btn>
      </div>
    </mi-form>
  </div>
</template>

<script>
  import { minLength, required } from '@/utils/validators'
  import { MIN_LENGTH_ERROR } from '@/utils/validators/errorMessages'
  import { TEST_CASES_VALIDATION_FORM, TEST_CASES_VALIDATION_TYPES } from '@/constants'
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import { mapActions, mapState } from 'vuex'
  import cloneDeep from 'lodash.clonedeep'
  import { removePlanningPeriodMask } from '@/utils/planningPeriod'
  import { createOnDemandExportsTestCasesValidation } from '@/api'
  import notify from '@/utils/notify'

  export default {
    name: 'TestCasesCreateValidationContent',
    components: { MiBtn },
    emits: ['close:dialog'],
    data: () => ({
      validationType: TEST_CASES_VALIDATION_TYPES,
      validationForm: {},
      OnDemandExportsTestCasesValidation: false,
      selectedType: TEST_CASES_VALIDATION_TYPES.PRODUCT_MODEL,
      isProductModel: true,
      options: [],
      inputValidationRules: [
        required,
        minLength(4)
      ]
    }),
    computed: {
      ...mapState('product-model', ['productModels']),
      ...mapState('rule-validation', ['userExports']),

      isPlanningPeriodEmpty() {
        const hasNumber = /\d/
        return !hasNumber.test(this.validationForm.planningPeriod)
      }
    },
    async created() {
      this.cleanForm()
      await this.getOptions()
    },
    methods: {
      ...mapActions('product-model', ['getProductModelsList']),
      ...mapActions('rule-validation', ['fetchUserExportsByType']),
      ...mapActions('validations', ['getTestCaseValidationsList']),

      async getOptions() {
        const promises = [
          this.getProductModelsList(),
          this.fetchUserExportsByType('basic')
        ]
        await Promise.allSettled(promises)
      },
      customPlanningPeriodValidationRules() {
        const PLANNING_PERIOD_LENGTH = 12
        if (this.isPlanningPeriodEmpty) return required()
        if (!this.isPlanningPeriodEmpty && this.validationForm.planningPeriod.includes('_')) {
          return MIN_LENGTH_ERROR.replace('{length}', PLANNING_PERIOD_LENGTH)
        }
        return true
      },
      handleValidationType(type) {
        this.cleanForm()
        this.selectedType = type
        this.isProductModel = this.selectedType.key === this.validationType.PRODUCT_MODEL.key
      },
      closeDialog() {
        this.$emit('close:dialog', false)
      },
      async handleSubmit() {
        try {
          this.OnDemandExportsTestCasesValidation = true
          const { testCaseId } = this.$route.params
          const {
            initialPP,
            finalPP
          } = removePlanningPeriodMask(this.validationForm.planningPeriod)
          const { productModel } = this.validationForm

          const result = await createOnDemandExportsTestCasesValidation({
            userExportID: productModel.id,
            testCaseId,
            initialPP,
            finalPP
          })

          if (result) {
            notify({
              title: 'COMPLETED',
              content: 'Test Case validation was successfully created.',
              type: 'dark',
              timeout: 2000,
              progress: false
            })
            this.closeDialog()
          }
        }
        catch (err) {
          throw new Error(err)
        }
        finally {
          this.OnDemandExportsTestCasesValidation = false
        }
      },
      cleanForm() {
        this.validationForm = cloneDeep(TEST_CASES_VALIDATION_FORM)
      }
    }
  }
</script>

<style lang="scss">
  .new-test-case-val {
    &__wrapper {
      height: 450px;
    }

    &__header {
      margin-top: 16px;
      margin-bottom: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &--toggle {
        background-color: $mi-silver-50;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 10px;
        width: 306px;
        height: 56px;
        flex: none;
        order: 0;
        flex-grow: 0;

        &__btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          width: 140px;
          height: 40px;
        }

        &__btn-selected {
          background-color: $mi-anthracite-800;
          /* stylelint-disable declaration-no-important */
          color: #ffffff !important;
        }
      }
    }

    &__form {
      padding: 24px;
      height: 250px;

      .mi-select-margin {
        margin-bottom: 32px;
      }

      &--mask {
        .q-field__prefix,
        input {
          font-size: .875rem;
          color: $mi-anthracite-400;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      width: 100%;
      gap: 16px;
      height: 84px;
      padding: 24px;
    }
  }

</style>
