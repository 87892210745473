import { createRule } from './common'
import { EXACT_LENGTH_ERROR, EXACT_LENGTHS_ERROR } from './errorMessages'

export default (length1, length2) => {
  let errorMessage
  if (length1 === 0) {
    errorMessage = EXACT_LENGTH_ERROR.replace('{length}', length2 || '')
  }
  else {
    errorMessage = EXACT_LENGTHS_ERROR.replace('{length1}', length1 || '').replace('{length2}', length2 || '')
  }
  const isValid = value => (value.toString().length === length1) || (value.toString().length === length2)

  return createRule({ isValid, errorMessage })
}
