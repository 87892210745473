import { helpers } from '@vuelidate/validators'
import { createRule } from './common'
import { MATCH_REGEX_ERROR } from './errorMessages'

export default (regexExpr, errMsg) => {
  const errorMessage = errMsg || MATCH_REGEX_ERROR.replace('{regex}', regexExpr || '')
  const isValid = value => helpers.regex(regexExpr)(value.toString())

  return createRule({ isValid, errorMessage })
}
