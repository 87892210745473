<template>
  <div class="header-validations__wrapper">
    <mi-btn
      class="close-btn"
      icon="close"
      icon-type
      icon-size="14px"
      dense
      fab
      flat
      @click="closeTestCaseValidation"
    >
      <span class="btn-text"> Close Validation </span>
    </mi-btn>
    <mi-separator
      color="$mi-anthracite-100"
      inset="inset"
      size="1.5px"
      spaced="0px"
      vertical="vertical"
    ></mi-separator>
    <mi-refresh-btn
      :is-loading="areTestCaseValidationsLoading"
      @clicked-refresh-btn="fetchTestCaseValidations"
    ></mi-refresh-btn>
  </div>
</template>

<script>
  import MiSeparator from '@/packages/@mi-library/MiSeparator/MiSeparator.vue'
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import { TEST_CASES_ROUTE } from '@/router/routeNames'

  export default {
    name: 'TestCasesValidationsTableHeader',
    components: { MiSeparator, MiBtn },
    props: {
      areTestCaseValidationsLoading: {
        type: Boolean,
        default: false
      },
      fetchTestCaseValidations: {
        type: Function,
        default: () => {}
      }
    },
    methods: {
      closeTestCaseValidation() {
        this.$router.push(
          TEST_CASES_ROUTE
        )
      }
    }
  }
</script>

<style lang="scss" scoped >
  .header-validations__wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .btn-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: .5px;
    text-align: center;
  }
</style>
