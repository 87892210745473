<template>
  <mi-dialog
    :model-value="modelValue"
    :persistent="isValidationDeleteInProgress"
    class="validation-delete-dialog"
    @update:model-value="emitUpdateModelValue"
  >
    <!-- Title -->
    <h5 class="q-mt-none q-mb-lg"> Delete Validation? </h5>

    <!-- Subtitle -->
    <p> Are you sure you want to delete validation <strong> {{ validationId }} </strong>? </p>
    <p v-if="isDeleteFromOnDemandExports">
      This will <strong>also delete the validation from the Daily list. </strong>
    </p>
    <p v-if="isUserExportDeleteFromDaily">
      This will <strong>also delete the validation from the On Demand Exports list. </strong>
    </p>

    <mi-separator class="q-mt-xl"></mi-separator>

    <template #actions>
      <mi-btn v-close-popup flat> Cancel </mi-btn>
      <mi-btn :loading="isValidationDeleteInProgress" @click="deleteValidation"> Delete </mi-btn>
    </template>
  </mi-dialog>
</template>

<script>
  import notify from '@/utils/notify'

  export default {
    name: 'ValidationDeleteDialog',
    props: {
      deleteFn: {
        type: Function,
        required: true
      },
      modelValue: {
        type: Boolean,
        required: true
      },
      validationId: {
        type: String,
        required: true
      },
      isDeleteFromOnDemandExports: {
        type: Boolean,
        required: false,
        default: false
      },
      isUserExportDeleteFromDaily: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['update:model-value', 'delete:validation'],
    data: () => ({
      isValidationDeleteInProgress: false
    }),
    methods: {
      async deleteValidation() {
        this.isValidationDeleteInProgress = true

        try {
          await this.deleteFn(this.validationId)

          this.emitUpdateModelValue()
          this.$emit('delete:validation')

          notify({
            title: 'Deleted',
            content: `Validation "${ this.validationId }" was successfully deleted`,
            type: 'positive'
          })
        }
        finally {
          this.isValidationDeleteInProgress = false
        }
      },
      emitUpdateModelValue(value = false) {
        this.$emit('update:model-value', value)
      }
    }
  }
</script>

<style lang="scss">
  .validation-delete-dialog {
    .q-dialog__inner > .mi-card {
      width: 924px;
      max-width: 924px;
      min-width: 580px;
    }
  }

  @media (max-width: $mi-responsive-large-width) {
    .validation-delete-dialog {
      .q-dialog__inner > .mi-card {
        width: 683px;
        max-width: 683px;
      }
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .validation-delete-dialog {
      .q-dialog__inner > .mi-card {
        width: 477px;
        max-width: 477px;
      }
    }
  }
</style>
