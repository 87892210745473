<template>
  <mi-dialog
    key="new-test-case-validation-dialog"
    :model-value="modelValue"
    class="new-test-case-validation-dialog"
    no-backdrop-dismiss
    custom-design-dialog
  >
    <new-validation-dialog-header
      new-validation-type="Test case"
      @close:dialog="handleCloseDialog"
    >
    </new-validation-dialog-header>

    <test-cases-create-validation-content
      @close:dialog="handleCloseDialog"
    >
    </test-cases-create-validation-content>
  </mi-dialog>
</template>

<script>
  import NewValidationDialogHeader
    from '@/components/rule-validation/on-demand-exports/new-validation-dialog/NewValidationDialogHeader.vue'
  import TestCasesCreateValidationContent
    from '@/components/validations/test-cases/new-validations/TestCasesCreateValidationContent.vue'

  export default {
    name: 'TestCasesCreateValidation',
    components: { TestCasesCreateValidationContent, NewValidationDialogHeader },
    props: {
      modelValue: {
        type: Boolean,
        required: true
      }
    },
    emits: ['update:model-value'],
    methods: {
      handleCloseDialog(val) {
        this.$emit('update:model-value', val)
      }
    }
  }
</script>

<style lang="scss">
  .new-test-case-validation-dialog {
    .q-dialog__inner > .mi-card {
      overflow: hidden;
      width: 924px;
      max-width: 924px;
      height: 545px;
    }
  }

  @media (max-width: $mi-responsive-large-width) {
    .new-test-case-validation-dialog {
      .q-dialog__inner > .mi-card {
        width: 683px;
        max-width: 683px;
        height: 545px;
      }
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .new-test-case-validation-dialog {
      .q-dialog__inner > .mi-card {
        width: 477px;
        max-width: 477px;
        height: 545px;
      }
    }
  }
</style>
