import { maxLength } from '@vuelidate/validators'
import { createRule } from './common'
import { MAX_LENGTH_ERROR } from './errorMessages'

export default (length, customMessage) => {
  const errorMessage = customMessage || MAX_LENGTH_ERROR.replace('{length}', length || '')

  const { $validator: isValid } = maxLength(length)

  return createRule({ isValid, errorMessage })
}
